/* src/components/Reports.css */

.reports-container {
  padding: 20px;
  max-width: 1000px;
  margin: 20px auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reports-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
}

.reports-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.reports-form label {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.reports-form .checkbox-group {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.reports-form .checkbox-group label {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.reports-form .checkbox-group input {
  margin-right: 10px;
}

.reports-form .react-tagsinput {
  padding: 15px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.save-button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
  width: 120px;
  margin: 0 auto;
}

.save-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.save-button:hover {
  background-color: #218838;
}

.history-container {
  width: 100%;
  margin-top: 40px;
}

.history-container h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.reports-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.reports-table th,
.reports-table td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
  font-size: 18px;
}

.reports-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.reports-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.reports-table tr:hover {
  background-color: #e9ecef;
}

.reports-table .icon {
  margin-right: 10px;
  cursor: pointer;
}

.reports-table .icon:hover {
  color: #007bff;
}

.small-button {
  padding: 4px 8px;
  margin-right: 4px;
  font-size: 12px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

input[type="checkbox"]:disabled {
  opacity: 0.5;
}

.error input,
.error .react-tagsinput-input {
  border-color: red;
}

@media (max-width: 600px) {
  .reports-form {
    gap: 15px;
  }

  .reports-form .checkbox-group {
    flex-direction: column;
  }

  .reports-form .react-tagsinput {
    font-size: 16px;
    padding: 10px;
  }

  .save-button {
    padding: 10px 20px;
    font-size: 16px;
  }

  .reports-table th,
  .reports-table td {
    padding: 10px;
  }

  .reports-table button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
