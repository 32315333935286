.numbers-details-modal {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 81, 99, 0.9);
    z-index: 2;
}

.numbers-details-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 40%;
    max-height: 50%;
    padding: 1.75rem 3vw;
    border-radius: 0.75rem;
    top: 0;
    overflow: hidden;
}

.numbers-details-header {
    display: flex;
    margin-bottom: 1rem;
}

.numbers-details-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.numbers-details-close-button {
    display: flex;
    background-color: transparent;
    border: none;
    margin-right: 0;
}

.numbers-details-close-button > svg {
    width: 2rem;
    height: 2rem;
    color: #b9b9b9;
}

.numbers-details-header {
    display: flex;
    justify-content: space-between;
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title {
    margin-left: 0.75rem;
}

.table {
    overflow-y: auto;
}

.state-empty {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: red;
}

