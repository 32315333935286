.content-wrapper {
	margin-bottom: 2rem;
}

.display-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 5em 0 0;
}

.list-chart-label {
	display: flex;
	flex-wrap: wrap;
	gap: 3.3em;
	font-size: 1.3em;
}

.chart-label {
	display: flex;
	align-items: center;
	gap: 0.6em;
	cursor: pointer;
}

.chart-label::before {
	content: '';
	display: block;
	width: 1em;
	height: 1em;
	border-radius: 50%;
}

.chart-label.green::before {
	background-color: #3ed48c;
}

.chart-label.yellow::before {
	background-color: #f2c056;
}

.chart-label.red::before {
	background-color: #f56666;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 1.3em;
	gap: 1.6em;
}

.list-filter {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1.6em;
}

.display-filter-label {
	min-width: 75px;
	text-align: center;
	border: 1px solid #f0f0f0;
	padding: 1em;
	border-radius: 2em;
	font-size: 1.3em;
	background: none;
	color: #3c5163;
	cursor: pointer;
}

.display-filter-label.active {
	background-color: #5faee3;
}

.display-filter-label.open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.wrapper-year-filter {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 12px 10px;
}

.arrow-icon {
	margin-top: -18px;
	height: 5px;
	font-size: 1.4em;
}

.custom-dropdown {
	position: absolute;
	top: 100%;
	background-color: #fff;
	border: 1px solid #f0f0f0;
	border-bottom-left-radius: 2em;
	border-bottom-right-radius: 2em;
	border-top: none;
	padding-bottom: 0.8em;
}

.custom-dropdown.activeYear {
	background-color: #5faee3;
}

.dropdown-option {
	padding: 5px 28px;
	cursor: pointer;
	border-top: 1px solid #f0f0f0;
}

@media screen and (max-width: 395px) {
	.display-filter-label {
		flex-grow: 1;
	}
}

@media screen and (max-width: 609px) {
	.display-filter-label {
		padding: 12px 15px !important;
	}
}

@media screen and (max-width: 768px) {
	.list-filter {
		width: 95%;
		align-self: center;
	}

	.chart-label {
		font-size: 1.06em;
	}

	.chart-label::before {
		width: 1em;
		height: 1em;
	}

	.list-chart-label {
		width: 95%;
		margin-left: auto;
		justify-content: end;
		padding-right: 2em;
	}

	.display-filter-label {
		padding: 12px 25px;
	}

	.dropdown-option {
		padding: 5px 44.5px;
		cursor: pointer;
		border-top: 1px solid #f0f0f0;
	}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
	.container {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.list-filter {
		width: 100%;
		flex-wrap: nowrap;
	}

	.list-chart-label {
		flex-wrap: nowrap;
		width: 100%;
		justify-content: end;
	}
}

@media screen and (min-width: 1025px) {
	.container {
		flex-direction: row;
	}

	.list-filter {
		flex-wrap: nowrap;
		min-width: 33.3em;
		gap: 2px !important;
	}

	.list-chart-label {
		flex-wrap: nowrap;
		justify-content: space-between;
		gap: 0.5rem;
	}
}
