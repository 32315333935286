.dropdown {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    align-items: baseline;

    .filter-title {
        font-weight: 700;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    .select {
        width: 7rem;
        height: 2.3125rem;
        border: 0.0625rem solid #f0f0f0;
        border-radius: 1.5rem;
        text-align: center;
    }
}