.table {
	display: flex;
}

.dash-table {
	white-space: nowrap;
}

.status-capitalize {
	text-transform: capitalize;
}

.actions-buttons {
	display: flex;
	flex-direction: row;

	.edit-button {
		margin-right: 2em;
	}

	.edit-icon {
		width: 1.6em;
		height: 1.6em;
	}
}

.status {
	padding: 0 5px;
	border-radius: 1em;
	font-size: 1em;
	display: inline;
	margin-top: 0.6em;
}

.client {
	color: #0000ff;
	padding: 0 5px;
	font-size: 1em;
}

.admin {
	color: #ff0000;
}

.branded {
    color: #3ED48C;
}

.cell-container {
    display: flex;
    flex-direction: column;
}

.trial {
    color: #ebae34;
}

.branded {
	color: #3ed48c;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
}

.buttons-pagination {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	flex-grow: 1;
	justify-content: center;
}

.info {
	flex-grow: 1;
	display: flex;
	justify-content: center;
}

.center-checkbox {
	display: flex;
	justify-content: center;
	padding-top: 30px;
}