.changes-info {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.attach-file {
    margin: 20px;
    background-color: #f8f8f8;
    cursor: pointer;
    text-align: center;
    border-radius: 50px;
    border: 1px solid #e1e1e1;
    overflow: hidden;
    display: flex;
    height: 43px;
    justify-content: center;
    align-items: center;

    span {
        color: #333;
    }
}

.input-text-area-wrapper {
    height: 150px;
    width: 95%;
}

.footer-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.button-footer {
    width: 90%;
    min-width: 200px;
}

.submit-footer {
	width: 100%;
	cursor: pointer;
	color: white;
	border: 0px none;
	background-color: #5faee3;

    padding: 15px 25px;
	border-radius: 50px;
	width: 100%;
}

@media screen and (min-width: 769px) {
    .footer-form {
        flex-direction: row;
    }
}