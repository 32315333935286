.header {
	box-shadow: 0px 1px 16px #4646461a;
	display: flex;
	margin: 10px 15px;
	border-radius: 12px;
	background-color: white;
	justify-content: end;
	align-items: center;
	padding: 15px 40px 15px 10px;
}

.header-user {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.header-items {
	display: flex;
	flex-direction: column;
}

.notification-icon {
	font-size: 2.5vh;
	margin-top: 0.5rem;
}

.header-avatar {
	height: 50px;
	width: 50px;
	padding: 5px;
	margin-right: 10px;
	border: solid 1px #5faee3;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header-avatar img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.header-welcome {
	font-size: 1em;
	font-weight: 400;
}

.header-item {
	font-size: 1.16em;
	font-weight: 500;
}

.hamburger-menu {
	display: none;
}

@media screen and (max-width: 768px) {
	.hamburger-menu {
		display: flex;
		margin-right: auto;
	}

	.hamburger-menu>svg {
		width: 30px;
		height: 30px;
	}

	.header-avatar {
		height: 40px;
		width: 40px;
	}
}