.sidebar {
	min-height: 100%;
	background-color: var(--element-bg-color);
	display: flex;
	flex-direction: column;
	width: 100vw;
	z-index: 1;
}

.header-sidebar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.6em;
}

.header-wrapper-image {
	align-self: center;
}

.header-wrapper-image > img {
	padding: 1.25em 0;
}

.line {
    border-bottom: 1px solid #F0F0F0;
}

.version-number {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.hamburger-menu-sidebar > svg {
	width: 3em;
	height: 3em;
}

@media screen and (min-width: 769px) {
	.sidebar {
		display: flex !important;
		max-width: 23.33em;
		z-index: 0;
	}

	.hamburger-menu-sidebar {
		display: none;
	}
}
