.call-logs-container {
    padding: 20px;
}

.call-logs-table {
    width: 100%;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.logs-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.logs-table th,
.logs-table td {
    padding: 16px 20px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.logs-table th {
    background-color: #f2f2f2;
}

.logs-table tbody tr:nth-child(odd) {
    background-color: #fff;
}

.logs-table tbody tr:hover {
    background-color: #f2f2f2;
}

.no-data-message {
    padding: 20px;
    font-size: 18px;
}

.call-logs-buttons {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.download-icon {
    margin-right: 1.5rem;
}