.menu-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 1.6em;
	gap: 2.5em;
}

.menu-wrapper > a {
	text-decoration: none;
	color: var(--text-color);
}

.menu-item {
	display: flex;
	align-items: center;
	font-size: 1.3em;
	font-weight: 500;
	border: none;
	background: none;
	cursor: pointer;
	text-decoration: none;
	height: 3.66em;
}

.logout {
	display: flex;
	align-items: center;
	border: none;
	background: none;
	cursor: pointer;
	text-decoration: none;
	height: 3.66em;
	padding: 0;
	margin-top: auto;
}

.logout > svg {
	padding: 0 0.83em;
	width: 1.6em;
	height: 1.6em;
}

.logout-text {
	font-size: 1.3em;
	font-weight: 500;
	color: var(--text-color);
}

.dashboard {
	margin-top: 5px;
	max-height: 20.83em;
	border-radius: 8px;
	text-decoration: none;
	border-radius: 8px;
}

.dashboard-info {
	display: flex;
	align-items: center;
	font-size: 1.3em;
	font-weight: 500;
	border: none;
	background: none;
	cursor: pointer;
	text-decoration: none;
	height: 3em;
}


.dropdown {
	display: flex;
	flex-direction: column;
}

.dropdown-item {
	color: var(--text-color);
	text-decoration: none;
}

ul {
	margin-block-start: 0px;
}

.dropdown-item > li {
	padding: 5px 0;
}

.active {
	border-radius: 8px;
	color: white !important;
	background-color: var(--active-bg-color);
}

.menu-item > svg,
.dashboard-info > svg {
	padding: 0 0.83em;
	width: 1.4em;
	height: 1.4em;
}

.menu-line {
	width: 100%;
	border-bottom: 1px solid #f0f0f0;
}

.active > img {
	color: #f0f0f0;
}

.logout-title {
	color: var(--text-color);
}

@media screen and (min-width: 769px) {
	.menu-wrapper {
		width: 22em;
		gap: 1em;
		padding: 0.83em;
	}

	.logout {
		margin-top: 0;
	}
}

@media screen and (min-width: 992px) {
	.menu-wrapper {
		width: 24.5em;
		padding-left: 0.83em;
		margin-right: 0.83em;
	}

	.menu-line {
		width: 90%;
	}

	.dashboard {
		max-width: 90%;
	}

	.menu-item {
		max-width: 90%;
	}
}
