.search-wrapper {
    padding: 10px 20px;
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    border-radius: 12px;
    background-color: #F8F8F8;
    border: 1px solid lightgray;
}

.search-input {
    padding: 10px 20px;
    border: none;
    background: none;
    height: 100%;
    width: 90%;
}

.search-icon {
    padding: 5px 10px;
    height: 25px;
    width: 20px;
}

.search-input:focus {
    outline-offset: 0px !important;
    outline: none;
}

.dropdown-search {
    display: block;
   background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px;
    border-radius: 10px;
    cursor: pointer;
    max-height: 200px;
    max-width: 180px;
    overflow: auto;
    padding: 0 10px;
    position: absolute;
    width: 100%;
    z-index: 100;
    top: 13.8rem;
}

.dropdown-row {
  border-radius: 5px;
  padding: 5px;
}

.dropdown-serach:focus-visible {
    background-color: F8F8F8;
}

.dropdown-row:hover {
    background-color: lightgray;
}

.search-loading-icon {
    padding: 5px 10px;
    height: 25px;
    width: 20px;
    color: #3498db;
    animation: spin 1s linear infinite;
}

.search-input:focus + .search-icon {
    background-color: #ecf0f1;
}
  
.loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.search-icon-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

@keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
