.loading {
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.dash-table {
    margin: 0 auto;
    width: 95%;
    text-align: left;
    margin-bottom: 0.8em;
}

.dash-table thead {
    background-color: #f8f8f8;
    border-radius: 1em;
}

.dash-table tr:nth-child(even) {
    background-color: #f8f8f8;
}

tr {
    cursor: pointer;
}

.buttons {
    display: flex;
    padding: 1.6em;
    justify-content: space-between;
}

.buttons>p {
    display: flex;
    font-weight: 300;
    font-size: 1.16em;
    padding: 0 20px;
    align-items: center;
    margin: 0;
}

.buttons>div>button {
    margin-right: 0.8em;
    padding: 0.8em 1.6em;
    background-color: #e1e1e1;
    border-radius: 2em;
    border: none;
    cursor: pointer;
}

th {
    margin-bottom: 0.8em;
    padding: 0.8em;
}

td {
    padding: 0.8em;
}

.search-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 1px 1px 16px #4646461a;
    margin: 0 1.25em 0.8em 1.25em;
    background-color: white;
    border-radius: 1em;
    gap: 0.8em;
    padding-top: 0.8em;
}

.search-input {
    width: 9em;
    border: 0.08em solid #f0f0f0;
    padding: 1.3em 2em;
    border-radius: 2em;
    margin-right: 0.6em;
}

.client-dropdown {
    margin-right: 0.6em;

    .select {
        width: 13em;
        height: 3.8em;
        border-radius: 2em;
    }
}

.input-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.8em 0;
}

.filter-action-button {
    display: flex;
    width: 7.5em;
    height: 2.6em;
    align-items: center;
    padding: 0.8em 1.3em;
    text-decoration: none;
    color: var(--text-color);
    border: 1px solid #f0f0f0;
    border-radius: 1em;
    background-color: var(--text-color);
    color: #fff;
    cursor: pointer;
    margin-right: 0.6em;
    justify-content: center;

    .filter-icon {
        margin-right: 0.41em;
    }
}

.charts {
    margin-top: 1.25em;
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    font-family: 'Poppins', sans-serif;
}

.action-buttons {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    margin: auto 0;
}

.table-container {
    padding: 1.3em 1.6em;
    box-shadow: 1px 1px 16px #4646461a;
    margin: 0 1.3em;
    margin-bottom: 0.8em;
    background-color: white;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    gap: 1.6em;
}

.search-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.6em;
    width: 95%;
}

.searching-result-dropdown {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;

    .search-text {
        font-weight: 600;
        padding: 1.3em;
        font-size: 1.1em;
    }
}

@media screen and (min-width: 1025px) {
    .search-wrapper {
        flex-direction: row;
    }

    .input-container {
        flex-wrap: nowrap;
    }

    .search-input {
        padding: 0 2em;
        max-height: 3.9em;
    }
}

.title-usa-map {
    font-size: 1.66em;
}

.client-feedback-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.client-feedback-button {
    background: linear-gradient(to right, #E0F3FF, #BFE8FF);
    color: #00517D;
    padding: 15px 30px;
    border: 2px solid rgba(0, 81, 125, 0.2);
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s, border-color 0.3s;
}

.client-feedback-button:hover {
    background-color: #CCE6FF;
    border-color: #004166;
}