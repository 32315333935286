.number-actions-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 1.2em;
    padding: 0 40px;
}

.pagination-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
}