.container-feedback-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feeback-title-client {
	font-size: 3em;
	font-weight: 600;
}

.feeback-value-client {
	font-size: 3em;
}