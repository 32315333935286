.list-cards {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	flex-wrap: wrap;
}

.card {
	flex: 1;
	padding: 20px 15px;
	display: grid;
	cursor: pointer;
	min-width: 130px;
}

.card-header {
	width: 100%;
	display: flex;
	justify-content: center;
}

.card-icon {
	margin: 0 auto;
	height: 30px;
}

.total-numbers {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 5.33em;
	gap: 25px;
	width: 100%;
}

.card-footer {
	display: flex;
	align-items: center;
	gap: 15px;
	color: #2b81c0;
	cursor: pointer;
	font-size: 1.5em;
}

.card-footer > svg {
	font-size: 1em;
}
