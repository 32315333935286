.list-charts {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 15px;
	margin-right: 30px;
	padding-bottom: 50px;
}

.content-chart {
	max-width: 30em;
}

.wrapper-chart {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0 10px 0;
}

.title-wrapper-chart {
	font-size: 1.36em;
	font-weight: 600;
	line-height: 1.5;
	text-align: center;
}

.total-number {
	margin-left: 8px;
	color: #5faee3;
}

.title-wrapper-chart-spam {
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	margin-bottom: 25px;
}

.total-numbers-spam {
	font-size: 14px;
	margin-left: 8px;
	color: red;
}

.spam-rate-text {
	fill: #3c5163;
	font-size: 2.6em;
	font-weight: 500;
}

.percent-text {
	fill: #3c5163;
	font-size: 6em;
	font-weight: 600;
}

.label-half-chart {
	margin-top: 30px;
	text-align: center;
}

.percentage-half-chart {
	font-size: 4.3em;
	font-weight: 600;
}

.title-half-chart {
	margin-top: 15px;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 25px;
}

.indicator-icon {
	padding: 30px;
	height: 80px;
	width: 80px;
	background-color: white;
	border-radius: 50%;
	box-shadow: 1px 1px 16px #4646461a;
}

.wrapper-coming {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 30em;
	min-height: 30em;
}

.label-coming {
	font-size: 3.33em;
	font-weight: 500;
	line-height: 1.5;
	margin-bottom: 25px;
	color: #b9b9b9;
}

.label-feedback {
	font-size: 2.16em;
	font-weight: 500;
}
.chart {
	width: 80%;
}

.chart {
	width: 80%;
}

.chart-button {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	width: 30em;
	min-height: 30em;
}