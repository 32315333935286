.user_input_title {
    display: flex;
    margin: 0 10px;
    padding: 10px 0;
}

.user_add_input {
    max-width: 250px;
	width: 100%;
	border: 1px solid #e1e1e1;
	background-color: #f8f8f8;
	padding: 15px 25px;
	border-radius: 50px;
}

.user_add_input:focus {
    border: 1px solid var(--active-bg-color);
	box-shadow: none;
	outline: none;
}