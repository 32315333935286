.filters {
	display: flex;
    flex-direction: column;
    align-items: baseline;

    .filter-title {
        font-weight: 700;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    .filter-options {
        display: flex;
        flex-wrap: wrap;
        gap: 0.2rem;
    }
}

.option {
	min-width: 5rem;
	text-align: center;
	border: 0.0625rem solid #f0f0f0;
	padding: 0.4375rem 0.375rem;
	border-radius: 1.5rem;
    background-color: white;
    cursor: pointer;
    margin: 0 auto;
}

.option.active {
    background-color: var(--text-color);
}

