h2 {
	margin: 0;
	padding: 20px;
}

.form-add {
	height: 100%;
	display: grid;
	padding: 0 20px;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto 1fr auto;
}

.form-add>div {
	margin-right: 10px;
	margin-bottom: 20px;
}

.input-star {
	color: red;
	margin: 0;
}

.input-title {
	margin: 0 30px;
	display: flex;
}

.input {
	display: flex;
	align-items: center;
}

.input-item {
	width: 100%;
}

.add-input {
	max-width: 250px;
	border: 1px solid #e1e1e1;
	padding: 15px 25px;
	border-radius: 50px;
	margin: 10px 25px 5px 25px;
	width: 100%;

	&::placeholder {
		color: #999;
		font-style: italic;
	}

	&:focus::placeholder {
		color: transparent;
	}
}

input[readonly] {
	background-color: #f1f1f1;
	cursor: none;
	pointer-events: none;
}

.add-input:focus {
	border: 1px solid var(--active-bg-color);
	box-shadow: none;
	outline: none;
}

.submit {
	max-width: 300px;
	width: 100%;
	cursor: pointer;
	color: white;
	border: 0px none;
	margin: 20px 25px;
	background-color: #5faee3;
}

.error-span {
	display: flex;
	color: red;
	font-weight: bold;
	white-space: nowrap;
	max-width: 250px;
	margin-left: 2vw;
}

.search {
	max-width: 225px;
	margin-left: 0px;
	margin-top: 10px;
}

.search-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.6em;
	width: 95%;
}

.search-loading-icon {
	padding: 5px 10px;
	height: 25px;
	width: 20px;
	color: #3498db;
	animation: spin 1s linear infinite;
}

.loading-indicator {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 999;
}

.search-results-dropdown {
	display: block;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.2) 0 5px 5px;
	border-radius: 10px;
	cursor: pointer;
	max-height: 200px;
	max-width: 180px;
	overflow: auto;
	padding: 0 10px;
	position: absolute;
	width: 100%;
	z-index: 100;
	top: 13.2rem;
    left: 22rem;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 768px) {
	.form-add {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin-top: 2rem;
		padding-bottom: 2rem;
	}

	.input-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.submit {
		margin: 0 auto;
	}
}

@media screen and (max-width: 280px) {
	.add-input {
		max-width: 150px;
	}
}

@media screen and (min-width: 860px) {
	.search-input {
		max-width: 180px;
	}
}

@media screen and (min-width: 769px) {
	.form-add {
		display: grid;
		grid-template-columns: repeat(1fr, 3);
	}

	.search-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.search {
		margin-left: 30px;
	}

	.search-input {
		padding: 10px 20px;
		border: none;
		background: none;
		height: auto;
		width: 90%;
	}
}

@media screen and (min-width: 820px) {
	.add-input {
		max-width: 150px;
	}
}

@media screen and (min-width: 912px) {
	.add-input {
		max-width: 200px;
	}
}

@media screen and (min-width: 1024px) {
	.add-input {
		max-width: 250px;
	}
}