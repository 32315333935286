.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.login-form {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-input-title {
    display: flex;
    margin: 0 10px;
    padding: 10px 0;
}

.login-add-input {
    max-width: 250px;
	width: 100%;
	border: 1px solid #e1e1e1;
	background-color: #f8f8f8;
	padding: 15px 25px;
	border-radius: 50px;
}

.login-add-input:focus {
    border: 1px solid var(--active-bg-color);
	box-shadow: none;
	outline: none;
}

.login-submit {
    max-width: 300px;
	width: 100%;
	cursor: pointer;
	color: white;
	border: 0px none;
	margin: 20px 0;
	background-color: #5faee3;
	width: 100%;
	padding: 15px 25px;
	border-radius: 50px;
}

.login-error {
    display: flex;
	justify-content: center;
	color: red;
	font-weight: bold;
	white-space: nowrap;
}

.forgot-password {
	max-width: 300px;
	width: 100%;
	cursor: pointer;
	color: white;
	border: 0px none;
	margin: 0px 0;
	background-color: #626c72;
	width: 100%;
	padding: 15px 25px;
	border-radius: 50px;
}

.loader-wrapper {
	margin-top: 50px;
}
