.footer {
	display: flex;
	justify-content: space-between;
	width: 100%;
	background: linear-gradient(93.92deg, #00507d 4.42%, #085c8f 23.6%, #146b9c 48.39%, #558fad 69.91%, #3b9cdc 94.23%);
	padding: 50px 20px;
}

.main-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 15px;
}

.main-info > p {
	margin: 0;
	font-size: 1.16em;
	font-weight: 400;
	padding-top: 5px;
	color: white;
}

.footer-nav {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 3.2rem;
	flex-wrap: wrap;
	justify-content: center;
	margin-right: 0.5rem;
}

.nav-el {
	text-decoration: none;
	background: none;
	border: none;
	color: white;
}

.arrow {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	padding: 10px;
	border-radius: 50px;
}

.arrow > svg {
	color: #5faee3;
	height: 20px;
	width: 25px;
}

@media screen and (max-width: 768px) {
	.footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
	}

	.main-info > p {
		font-size: 0.8em;
	}

	.footer-nav > a {
		font-size: 1.06em;
	}
}
