.form-manage-numbers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto;
    gap: 5px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.input-item {
    flex: 1;
    margin-right: 10px;
}

.input-item:last-child {
    margin-right: 0;
}

.input-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.add-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    text-align: left;
}

.input-selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    max-width: 303px;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-color: #f8f8f8;
    padding: 15px 25px;
    border-radius: 50px;
    margin: 10px 25px 5px 25px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    background-size: 10px auto;
}

.error-span {
    color: red;
}

.create-number {
    grid-column: span 3;
    justify-self: center;
    align-self: center;
    padding: 10px 20px;
    background-color: #3ed48c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.create-number:hover {
    background-color: #3ed48c;
}

.number-input-section {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.number-input-section textarea {
    width: 100%;
    min-height: 100px;
    margin-bottom: 10px;
    padding: 5px;
    resize: vertical;
}

.number-input-section button {
    padding: 10px 20px;
    background-color: #3ed48c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.number-input-section button:hover {
    background-color: #3ed48c;
}

.separator {
    width: 100%;
    height: 2px;
    background-color: #ccc;
    margin: 20px 0;
}