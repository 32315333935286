.client-feedback {
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    padding: 50px;
}

.client-title {
    font-size: 40px;
    margin-bottom: 20px;
    display: block;
    color: #5FAEE3;
}

.company-name {
    font-size: 20px;
    font-weight: bold;
    color: #00517D;
    margin-bottom: 20px;
}

.feedback-table {
    margin-bottom: 20px;
}

.feedback-dash-table {
    border-collapse: collapse;
    width: 100%;
    padding: 20px;
    box-shadow: 1px 1px 16px #4646461a;
    border-radius: 20px;
}

.feedback-dash-table tbody {
    border-collapse: collapse;
}

.feedback-dash-table th,
.feedback-dash-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    height: 40px;
}

.feedback-dash-table th {
    background-color: #f8f8f8;
    color: #00517D;
}

.feedback-dash-table tbody tr {
    background-color: #fff;
    color: #00517D;
}

.feedback-dash-table tbody tr td:first-child {
    color: #00517D;
    font-weight: bold;
}

.actions-container {
    display: flex;
    flex-direction: row;
    height: 90px;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 1px 1px 16px #4646461a;
}

.hide-profanity-container {
    display: flex;
    align-items: center;
    padding: 20px;
}

.hide-profanity-container input[type="checkbox"] {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #999;
    outline: none;
    margin-right: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.hide-profanity-container input[type="checkbox"]::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.hide-profanity-container input[type="checkbox"]:checked::before {
    background-color: #5FAEE3;
}

.hide-profanity-container label {
    font-size: 16px;
    color: #00517D;
    cursor: pointer;
}

.feedback-search-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
}

.feedback-search-container input[type="text"] {
    width: 300px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    background: none;
}

.feedback-search-container input[type="text"]:focus {
    border-bottom-color: #00517D;
}

.feedback-search-container .icon {
    cursor: pointer;
}