.check-results {
    padding: 2rem;
}

.result-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.result-section {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    overflow: hidden;
}

.section-header {
    background-color: #f1f1f1;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-header:hover {
    background-color: #e1e1e1;
}

.check-results-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
}

.results-table th,
.results-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.results-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.operation-buttons {
    display: flex;
    gap: 0.5rem;
}

/* Estilos para botões */
.view-details-button,
.run-button,
.delete-button,
.csv-button {
    background-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: background-color 0.3s, color 0.3s;
}

.view-details-button:hover,
.run-button:hover,
.delete-button:hover,
.csv-button:hover {
    background-color: #6E7B8B;
    color: white;
}

.run-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.divider-buttons {
    margin: 0 0.5rem;
}

@media screen and (max-width: 768px) {
    .operation-buttons {
        flex-direction: column;
        gap: 0.5rem;
    }

    .view-details-button,
    .run-button,
    .delete-button,
    .csv-button {
        width: 100%;
    }
}
