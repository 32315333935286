h2 {
	margin: 0;
	padding: 20px;
}

.form-add {
	height: 100%;
	display: grid;
	padding: 0 20px;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto 1fr auto;
}

.form-add > div {
	margin-right: 10px;
	margin-bottom: 20px;
}

.input-star {
	color: red;
	margin: 0;
}

.input-title {
	margin: 0 30px;
	display: flex;
	/* margin-bottom: 10px; */
}

.input {
	display: flex;
	align-items: center;
}

.input-item {
	width: 100%;
}

.add-input {
	max-width: 250px;
	border: 1px solid #e1e1e1;
	padding: 15px 25px;
	border-radius: 50px;
	margin: 10px 25px 5px 25px;
	width: 100%;
	
	&::placeholder {
		color: #999;
		font-style: italic;
	}

	&:focus::placeholder {
		color: transparent;
	}
}

input[readonly] {
	background-color: #f1f1f1;
	cursor: none;
	pointer-events: none;
}

.add-input:focus {
	border: 1px solid var(--active-bg-color);
	box-shadow: none;
	outline: none;
}

.text-area-wrapper {
	width: 100%;
	height: 150px;
	resize: none;
	grid-column: 1 / span 2;
}

.text-area {
	border: 1px solid #e1e1e1;
	margin: 10px 0 0 20px;
	height: 100%;
	width: 95%;
	display: flex;
	border-radius: 14px;
	padding: 0 10px;
}

.text-area:focus {
	border: 1px solid var(--active-bg-color);
	box-shadow: none;
	outline: none;
}

.submit {
	max-width: 300px;
	width: 100%;
	cursor: pointer;
	color: white;
	border: 0px none;
	margin: 20px 25px;
	background-color: #5faee3;
}

.error-span {
	display: flex;
	color: red;
	font-weight: bold;
	white-space: nowrap;
	max-width: 250px;
	margin-left: 2vw;
}

.zip-code {
	display: flex;
	align-items: center;
	border-radius: 50px;
	border: 1px solid lightgray;
	height: 15px;
	max-width: 250px;
	padding: 15px 25px;
	margin: 10px 25px 5px 25px;

	.input {
		width: 100%;
		border: 1px solid transparent;
		border-radius: 50px;

		&:focus {
			outline-offset: 0px !important;
			outline: none;
		}

		&::placeholder {
			color: #999;
			font-style: italic;
		}

		&:focus::placeholder {
			color: transparent;
		}
	}

	.icon {
		height: 25px;
		width: 20px;
	}
}

@media screen and (max-width: 768px) {
	.form-add {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin-top: 2rem;
		padding-bottom: 2rem;
	}

	.input-wrapper,
	.zip-code {
		width: 100%;  
		display: flex;
		justify-content: center;
	}

	.input-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.submit {
		margin: 0 auto;
	}
}

@media screen and (min-width: 769px) {
	.form-add {
		display: grid;
		grid-template-columns: repeat(1fr, 3);
	}

	.input-wrapper,
	.zip-code {
		width: 100%;
		display: flex;
	}
}