.phone-modal {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 81, 99, 0.9);
}

.phone-modal-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 80%;
    height: 80%;
    padding: 1.75rem 3vw;
    border-radius: 0.75rem;
    position: relative;
	overflow-y: auto;
}

.phone-modal-header {
    display: flex;
}

.phone-header-title {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.phone-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
}

.phone-modal-close-button > svg {
    width: 2rem;
    height: 2rem;
    color: #b9b9b9;
}

.phone-modal-header {
    display: flex;
    justify-content: space-between;
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 2.6em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title {
    margin-left: 0.75rem;
}

.name {
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 1.33em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.5rem;
    gap: 20px 0
}

.checkbox-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px 0;
}

.action-button {
    margin-top: 3rem;
}

.save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10.75rem;
    height: 4rem;
    border: 0 none;
    background: var(--color-green, #3ED48C);
    border-radius: 3.125rem;

    color: var(--ffffff, #FFF);
    font-family: Poppins;
    font-size: 1.33em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .save-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.75rem;
    }
}

.area-settings {
    margin-top: 1.75rem;

    .title {
        font-family: Poppins;
        font-size: 1.16em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem; 
    }
}

.region {
    display: flex;
    align-items: center;
    height: 0.9375rem;
    max-width: 15.625rem;
    padding: 0.9375rem 1.5625rem;
    margin-top: 0.5rem;
    border-radius: 3.125rem;
    border: 0.0625rem solid var(--color-grey, #E1E1E1);
    background: var(--bg-grey-light, #F8F8F8);

    .input {
        width: 100%;
        border: 0.0625rem solid transparent;
        border-radius: 3.125rem;
        background: var(--bg-grey-light, #F8F8F8);

        &:focus {
            outline-offset: 0px !important;
            outline: none;
        }

        &::placeholder {
            color: #999;
              font-style: italic;
        }

        &:focus::placeholder {
            color: transparent;
        }
    }

    .icon {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.edge-container {
    margin-top: 2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    background: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);

}

.ftc-container {
    margin-top: 2rem;
    cursor: pointer;
    .checkbox-section {
        gap: 0;
    }
}