.details-wrapper {
    padding: 10px 20px;
    height: 100%;
}

.search-result {
    display: flex;
    font-size: 1.66em;
}

.search-result-title {
    margin: 0;
    font-weight: bold;
}

.details-button-wrapper {
    padding: 20px 40px;
    gap: 50px 0;

}

.download-numbers {
    padding: 0px 20px;
    cursor: pointer;
    color: #5FAEE3;
    display: flex;
    align-items: center;
}

.download-numbers > svg {
    padding: 0px 0.625rem;
    width: 1rem;
    height: 1rem;
}

.add-numbers {
    padding: 0px 10px;
    cursor: pointer;
    color: #3ED48C;
    display: flex;
    align-items: center;
}

.add-numbers > svg {
    padding: 0px 0.625rem;
    width: 1rem;
    height: 1rem;
}

.details-buttons {
    display: flex;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
}

.details-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    text-decoration: none;
    color: var(--text-color);
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    margin-right: 5px;
    width: 100px;
    height: 50px;
    gap: 10px;
}

.numbers-buttons {
    display: flex;
    justify-content: flex-end;
    font-size: 1.08em;
    padding: 20px 40px;
    gap: 5px;
    margin-left: auto;
}

.active-details {
    color: var(--element-bg-color);
    background-color: var(--text-color);
}

.filter-options-button {
    display: flex;
    justify-content: center;
    align-items: center;

    .filter-icon {
        margin-right: 0.3125rem;
    }
}

.filter-options-button.button-pressed {
    color: #fff;
    background-color: var(--text-color);
}

.filters-container {
    padding: 1.5rem 1.8rem;
}

.filters-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    border: 0.0625rem solid #f8f9fa;
    border-radius: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
}

.filters-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

    .filters {
        margin-bottom: 1.5rem;
        margin: 0 auto 2rem auto;
    }
}

.filter-button {
    display: flex;
    height: 2rem;
    align-items: center;
    padding: 0.625rem 1rem;
    text-decoration: none;
    color: var(--text-color);
    border: 1px solid #F0F0F0;
    border-radius: 0.75rem;
    background-color: var(--text-color);
    color: #fff;
    cursor: pointer;
    margin-top: 1.5rem;
    margin-right: 1rem;

    .filter-icon {
        margin-right: 0.3125rem;
    }
}

.dropdown-area {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.filter-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    margin-top: 1rem;
}

.filter-name {
    font-size: 1.6em;
    font-weight: 900;
}

.filter-action-buttons {
    display: flex;
}

.delete-numbers {
    padding: 0px 10px;
    cursor: pointer;
    color: red;
    display: flex;
    align-items: center;
}

.delete-numbers > svg {
    padding: 0px 0.625rem;
    width: 1rem;
    height: 1rem;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 2rem 0;

    .pagination-text {
        font-weight: 600;
        margin-right: 1rem;
        padding: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .details-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }   
}