.checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 0;
    justify-content: center;

    & label {
        display: flex;
        margin-right: 1.75rem;
    }
}

.text {
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
}

.text.selected.Active,
.text.selected.Branded {
    color: var(--color-green, #3ED48C);
}

.text.selected {
    color: var(--color-red, #F56666);
}

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top: auto;
    margin-bottom: auto;
    font: inherit;
    width: 1.1875rem;
    height: 1.1875rem;
    border: 0.0625rem solid #E1E1E1;
    border-radius: 0.21875rem;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 12px;
}

input[type=checkbox]::before {
    content: "\2713";
    width: 0.75rem;
    height: 0.75rem;
    -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: CanvasText;
}

input[type=checkbox]:checked::before {
    transform: scale(1);
    background-color: #F56666;
    color: #F56666;
}

input[type=checkbox]:checked {
    border: 0.0625rem solid #F56666;
}

.checkbox.Active:checked::before,
.checkbox.Branded:checked::before {
    background-color: #3ED48C;
    color: #3ED48C;
}

.checkbox.Active:checked,
.checkbox.Branded:checked {
    border: 0.0625rem solid #3ED48C;
}