.check-results-modal {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 81, 99, 0.9);
    z-index: 2;
}

.check-results-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 85%;
    max-height: 85%;
    padding: 1.75rem 3vw;
    border-radius: 0.75rem;
    top: 0;
    overflow: hidden;
}

.check-results-header {
    display: flex;
    margin-bottom: 1rem;
}

.check-results-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.check-results-close-button {
    display: flex;
    background-color: transparent;
    border: none;
    margin-right: 0;
}

.check-results-close-button > svg {
    width: 2rem;
    height: 2rem;
    color: #b9b9b9;
}

.check-results-header {
    display: flex;
    justify-content: space-between;
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title {
    margin-left: 0.75rem;
}

.table {
    overflow-y: auto;
}

.check-results-empty {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: red;
}

.details-name,
.details-total {
    margin-right: 1.2rem;
    font-size: 1.2rem;
}

.approve-all-button-modal {
    background-color: transparent;
    color: #4CAF50;
    font-size: 1rem;
    font-weight: 700;
    border: none;
}

.deny-all-button-modal {
    background-color: transparent;
    color: #FF0000;
    font-size: 1rem;
    font-weight: 700;
    border: none;
}

.approve-button-modal {
    background-color: transparent;
    color: #4CAF50;
    font-size: 0.75rem;
    font-weight: 600;
    border: none;
    padding-left: 0;
}

.deny-button-modal {
    background-color: transparent;
    color: #FF0000;
    font-size: 0.75rem;
    font-weight: 600;
    border: none;
}

.separator {
    font-size: 1.2rem;
}

.details-summary {
    display: flex;
    margin-left: 0.75rem;
    margin-bottom: 2rem;
}

.spam-badge {
    background-color: red;
    font-family: Poppins;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    width: 50px;
  }

  .fail-badge {
    background-color: yellow;
    font-family: Poppins;
    color: black;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    width: 50px;
  }

  .clean-badge {
    background-color: #03bafc;
    font-family: Poppins;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    width: 50px;
  }

  .badge-container {
    display: inline-flex;
    flex-direction: column;
  }