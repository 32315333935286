.custom {
 margin: 20px;
}

.upload-title {
    margin: 0 20px;
}

input[type=file] {

    display: none;
}

img {
    padding-bottom: 5px;
}

.custom {
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 200px;
    height: 150px;
    border-radius: 12px;
    background-color: #F0F0F0;
}

.custom > svg {
    width: 40px;
    height: 40px;
}

.custom-text {
    padding: 5px;
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ftc-results {
    padding: 20px;
}

.ftc-result-item > ul {
    margin: 0;
}

.ftc-result-row {
    padding: 15px;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.ftc-result-title {
    margin: 0px;
}

.ftc-result-companies {
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    padding: 0 15px;
}

.ftc-result-item {
    padding: 15px 0;
    border-bottom: 1px solid #F0F0F0;
    cursor: pointer;
}

.ftc-result-item:first-child {
    cursor: default
}

.ftc-result-item:last-child {
    border-bottom: none
}

.ftc-result-number {
    padding: 10px;
}

.ftc-error-message {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem;
    background-color: #ffe6e6;
    border: 1px solid #ff4d4d;
    color: #ff4d4d;
}

.ftc-error-icon {
    margin-right: 0.5rem;
    font-size: 2em;
}

.upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.upload-content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .upload-wrapper {
        flex-direction: row;
    }
}

.custom.inactive {
  opacity: 0.5;
  cursor: not-allowed;
}