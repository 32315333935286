.save {
    padding: 15px 25px;
    border-radius: 50px;
    margin: 10px 25px 5px 25px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 50px;
    background-color: var(--save-bg-color);
    color: var(--element-bg-color);
}

.save > svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    max-width: 303px;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-color: #f8f8f8;
    padding: 15px 25px;
    border-radius: 50px;
    margin: 10px 25px 5px 25px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    background-size: 10px auto;
}

.selector:focus {
    border: 1px solid var(--active-bg-color);
    box-shadow: none;
    outline: none;
}

.zip-code {
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    height: 15px;
    padding: 15px 25px;
    margin: 10px 25px 5px 25px;

    .input {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 50px;

        &:focus {
            outline-offset: 0px !important;
            outline: none;
        }

        &::placeholder {
            color: #999;
            font-style: italic;
        }

        &:focus::placeholder {
            color: transparent;
        }
    }

    .icon {
        height: 25px;
        width: 20px;
    }
}

.input-item {
    width: 100%;
}

.delete {
    padding: 15px 25px;
    border-radius: 50px;
    margin: 10px 25px 5px 25px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 50px;
    background-color: #FF0000;
    color: var(--element-bg-color);
}

.delete > svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}
