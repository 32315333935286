.test-phones {
  .search-wrapper {
    padding: 0;
    justify-content: flex-start;
    gap: 0;
  }

  .search {
    max-width: none;
    background-color: white;
    width: 15rem;
  }

  .search-input {
    border: transparent;
    width: 11em;
  }

  .dropdown-search {
    top: 47.9rem;
    right: 56.5rem;
  }
}

.phone-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
}

.carrier {
  margin-right: 1.25rem;
}

.carrier .company-name {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0.625rem;
  font-size: 2.5rem;
}

.device-list {
  overflow-y: auto;
  max-height: 14rem;
  padding: 0 1rem;
}

.device {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.device input[type="checkbox"],
.device label {
  display: inline-block;
  vertical-align: middle;
}

.device label {
  margin-left: 0, 3125rem;
}

.action-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
}

.test-phone-number {
  display: flex;
  flex-direction: column;
}

.check-item {
  display: flex;
  margin: 1rem 0;
}

.check-title {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.check-text {
  width: 10rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.input-fields {
  width: 15rem;
  height: 2rem;
  font-size: 1.5rem;
}

.campaign-check,
.spot-check {
  display: flex;
  flex-direction: column;
}

.test-numbers {
  margin: 0;
}

.test-numbers-title {
  margin: 0;
  margin-bottom: 1rem;
}

.create-button {
  background-color: #ff3333;
  color: white;
  border: none;
  width: 14rem;
  height: 5.3125rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 700;
  border-radius: 40px;
}

.bottom-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.additional-label {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1200px) {
  .action-section {
    flex-direction: column;
  }

  .phone-list {
    flex-direction: column;
  }

  .input-fields {
    width: 12rem;
  }

  .check-text {
    width: 12rem;
  }

  .phone-info {
    font-size: 1rem;
  }

  .carrier .company-name {
    font-size: 2rem;
  }

  .run-button {
    width: 3.125rem;
    height: 2.8125rem;
    font-size: 1rem;
  }
}

.custom-checkbox {
  width: 1.875rem !important;
  height: 1.875rem !important;
  border: 0.125rem solid #E1E1E1 !important;
  border-radius: 0.25rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.custom-checkbox::after {
  content: "\2713";
  font-size: 1.25rem;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-checkbox.checked::after {
  color: #F56666;
}

.custom-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.3125rem;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .input-fields {
    width: 12rem;
  }

  .check-text {
    width: 12rem;
  }

  .phone-info {
    font-size: 1rem;
  }

  .carrier .company-name {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .input-fields {
    width: 20rem;
  }
}

@media screen and (min-width: 1801px) and (max-width: 2200px) {
  .input-fields {
    width: 30rem;
  }
}

@media screen and (min-width: 2201px) {
  .input-fields {
    width: 35rem;
    height: 3rem;
  }

  .check-title {
    font-size: 2rem;
  }

  .check-text {
    font-size: 1.5rem;
  }

  .phone-info {
    font-size: 1rem;
  }

  .check-text {
    width: 20rem;
    font-size: 1.5rem;
  }

  .test-numbers-title {
    font-size: 1.2rem;
  }
}