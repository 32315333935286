.popup {
    font-family: Poppins;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 81, 99, 0.9);
}

.popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 23%;
    padding: 1.75rem 3rem;
    border-radius: 0.75rem;
}

.popup-header {
    display: flex;
    align-items: baseline;
}

.popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.popup-header {
    display: flex;
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title {
    margin-left: 0.75rem;
}

.buttons-region {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 0 none;
    background: var(--color-green, #3ED48C);
    border-radius: 3.125rem;

    color: var(--ffffff, #FFF);
    font-family: Poppins;
    font-size: 1.33em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cancel {
    margin-left: 1rem;
    background: var(--color-green, #808080);
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.75rem;
    text-align: center;

    .title {
        font-family: Poppins;
        font-size: 1.33em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem; 
    }

    .dynamic {
        margin-top: 1.33em;
        font-weight: 600;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 1.33em;
    }

    .popup-header {
        width: 100%;
    }

    .popup-content {
        padding: 1.5rem;
        width: 70%;
    }
}