.user_submit {
    max-width: 300px;
	width: 100%;
	cursor: pointer;
	color: white;
	border: 0px none;
	margin: 20px 0;
	background-color: #5faee3;
	width: 100%;
	padding: 15px 25px;
	border-radius: 50px;
}