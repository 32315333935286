.feedback-messages {
    display: flex;
    justify-content: flex-start;
    padding: 0.625rem 1.25rem;
}

.no-results-message {
    margin-top: 0.625rem;
    padding: 0.625rem;
    border: 0.0625rem solid #ff0000;
    border-radius: 10px;
    background-color: #ffe6e6;
    text-align: center;
    width: 25rem;
    max-width: 80%;
    color: #ff0000;
}

.search-instruction {
    margin-top: 0.625rem;
    padding: 0.625rem;
    border: 0.0625rem solid #6c757d;
    border-radius: 0.625rem;
    text-align: center;
    width: 25rem;
    max-width: 80%;
    color: #6c757d;
}

.search-loading-icon {
    padding: 5px 10px;
    height: 25px;
    width: 20px;
    color: #3498db;
    animation: spin 1s linear infinite;
}

.search-input:focus + .search-icon {
    background-color: #ecf0f1;
}
  
.loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.search-icon-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

@keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  