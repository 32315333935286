:root {
	height: 100%;
	min-height: 100vh;
	--element-bg-color: white;
	--active-bg-color: #2b81c0;
	--text-color: #3c5163;
	--save-bg-color: #3ed48c;
}

#root {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8f9fa;
	color: var(--text-color);
	font-size: 10px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper {
	max-width: 100vw;
	height: 100%;
	/* max-height: 100%; */
}

.main {
	height: 100%;
	width: 100%;
	display: flex;
}

.content {
	min-height: 100%; 
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
}

.app {
	width: 100%;
}

.content-wrapper {
	box-shadow: 1px 1px 16px #4646461a;
	display: flex;
	flex-direction: column;
	margin: 0 15px;
	margin-bottom: 10px;
	background-color: white;
	border-radius: 12px;
}

.content-wrapper > h2 {
	padding: 20px 20px;
	max-height: 35px;
}

.loader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.loader-text {
	color: #2b81c0;
}

.empty {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0;
}

.hidden {
	display: none !important;
}

@media screen and (min-width: 769px) {
	body {
		font-size: 12px;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
	}
}
