.selected-buttons{
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    margin-bottom: 25px;
    font-size: 1.2em;
}

.delete-selected-numbers {
    padding: 0px 10px;
    cursor: pointer;
    color: red;
    display: flex;
    align-items: center;
}

.delete-selected-numbers > svg {
    padding: 0px 0.625rem;
    width: 1rem;
    height: 1rem;
}

.select-all-numbers {
    padding: 0px 10px;
    cursor: pointer;
    color: #5FAEE3;
    display: flex;
    align-items: center;
}

.select-all-numbers > svg {
    padding: 0 0.625rem;
    width: 1rem;
    height: 1rem;
}