.state-borders {
    fill: none;
    stroke: #fff;
    stroke-width: 1px;
}

.map-container {
    position: relative;
}

.zoom-buttons {
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    background-color: #2b81c0;
    padding: 15px;
    border-radius: 10px 0px 10px 0px;
    gap: 10px;
    z-index: 1;
}

.zoom-button {
    background: none;
    border: none;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    color: #fff;
}

.zoom-button > svg {
    width: 25px;
    height: 25px;
}

@media screen and (min-width: 769px) {
	.zoom-buttons {
		padding: 15px;
	}

	.zoom-button > svg {
		width: 25px;
		height: 25px;
	}
}