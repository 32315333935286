.notifications-popup {
    font-family: Poppins;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 81, 99, 0.9);
    z-index: 9999;
}

.notifications-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 75%;
    padding: 1.75rem 3rem;
    border-radius: 0.75rem;
}

.notifications-popup-header {
    display: flex;
    color: var(--call-black, #3C5163);
    font-family: Poppins;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.buttons-region {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 0 none;
    background: var(--color-green, #3ED48C);
    border-radius: 3.125rem;

    color: var(--ffffff, #FFF);
    font-family: Poppins;
    font-size: 1.33em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cancel {
    margin-left: 1rem;
    background: var(--color-green, #808080);
}

.notifications-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    min-width: 0;
    margin-top: 2rem;

    .title {
        font-family: Poppins, serif;
        font-size: 1em;
        font-weight: 700;
        line-height: 1.1rem;
        margin-top: 1rem;
    }
}

.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreen-modal-content {
    background: white;
    width: 70vw;
    height: 70vh;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.item {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    padding: 0.3rem;
}

.item-text {
    font-weight: 700;
    margin-left: 0.5rem;
}

.notification-modal-close-button {
    background-color: transparent;
    border: none;
}