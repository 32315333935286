.file-upload-modal {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 81, 99, 0.9);
}

.file-upload-modal-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 80%;
    padding: 1.75rem 3vw;
    border-radius: 0.75rem;
    position: relative;
    overflow-y: auto;
    max-height: 70%;
}

.file-upload-modal-close-button {
    display: flex;
    flex-direction: row-reverse;
    background-color: transparent;
    border: none;
}

.file-upload-modal-close-button>svg {
    width: 2rem;
    height: 2rem;
    color: #b9b9b9;
}

.file-upload-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 12px;
}

.file-upload-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.file-upload-error-message {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem;
    background-color: #ffe6e6;
    border: 1px solid #ff4d4d;
    color: #ff4d4d;
}

.file-upload-error-icon {
    margin-right: 0.5rem;
    font-size: 2em;
}

.file-upload-success-message {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem;
    background-color: #e6f7e2;
    border: 1px solid #a0d468;
    color: #4caf50;
}

.file-upload-success-icon {
    margin-right: 0.5rem;
    font-size: 2em;
    color: #4caf50;
}

.information-summary {
    display: flex;
    flex-direction: row;
    margin-left: 1.25rem;
    align-items: baseline;
}

.details-summary-header {
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
    margin-bottom: 2rem;
}